import React       from 'react';
import { graphql } from 'gatsby';
import Img         from 'gatsby-image';
import {
  Box,
  Heading,
  Flex,
  chakra,
  HStack,
  Button,
}                  from '@chakra-ui/react';

import Carousel     from '@interness/web-core/src/components/media/Carousel/Carousel';
import Wrapper      from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import Spacer       from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Link         from '@interness/web-core/src/components/elements/Link/Link';
import PropertyGrid from '@interness/immo-addon/src/components/PropertyGrid';

function IndexPage(props) {
  return (
    <>
      <Carousel>
        {props.data.headerImages.images.map((image) => (
          <Img key={image.file.localFile.name} fluid={image.file.localFile.childImageSharp.fluid}
               alt={image.file.localFile.name}/>
        ))}
      </Carousel>
      <Wrapper>
        <section>
          <Flex px={4} py={32} mx="auto">
            <Box mx="auto" w={{ lg: 8 / 12 }}>
              <chakra.h1
                mb={2}
                fontSize="xs"
                fontWeight="semibold"
                letterSpacing="wide"
                color="gray.400"
                textTransform="uppercase"
              >
                Bert-Ulrich Weber Immobilien
              </chakra.h1>
              <chakra.h2
                mb={3}
                fontSize={{ base: '3xl', md: '4xl' }}
                fontWeight="bold"
                lineHeight="shorter"
              >
                Ihre Immobilie in guten Händen
              </chakra.h2>
              <chakra.p mb={5} color="gray.500" fontSize={{ md: 'lg' }}>
                Wir sind Immobilienfachleute mit langjähriger Erfahrung und bieten alle Dienstleistungen rund um die
                Vermarktung und Verwaltung Ihrer Immobilie.
              </chakra.p>
              <HStack>
                <Button
                  as={Link}
                  to="/kontakt"
                  w={{ base: 'full', sm: 'auto' }}
                  variant="solid"
                  colorScheme="brand"
                  size="lg"
                  mb={{ base: 2, sm: 0 }}
                  cursor="pointer"
                >
                  Kontaktieren
                </Button>
                <Button
                  as={Link}
                  to="/referenzen"
                  w={{ base: 'full', sm: 'auto' }}
                  mb={{ base: 2, sm: 0 }}
                  size="lg"
                  cursor="pointer"
                >
                  Referenzen
                </Button>
              </HStack>
            </Box>
          </Flex>
        </section>
        <section>
          <p>Willkommen auf der Webseite von Bert Ulrich Weber, Ihrem erfahrenen Immobilienmakler für Bauland und
            Neubauvorhaben in Burscheid und Umgebung. Als Experte in der Immobilienbranche widmen wir uns mit
            Leidenschaft und Fachwissen der Erschließung von Grundstücken, der Entwicklung von Bauprojekten und der
            Vermarktung von Immobilien.</p>
          <p>Unser aktuelles Highlight ist das Bauprojekt „Burscheid am Markt“, ein zukunftsorientiertes Neubauvorhaben,
            das modernen Wohnungsbau mit der optimalen Nutzung von Baulandflächen verbindet. Wir spezialisieren uns auf
            die Projektierung und den Verkauf von Wohnimmobilien und bieten umfassende Dienstleistungen von der
            Wertermittlung bis hin zur Baureifmachung der Grundstücke.</p>
          <p>Unser Angebot umfasst sowohl attraktive Immobilien in Burscheid als auch maßgeschneiderte Lösungen für
            individuelle Bauprojekte. Wir unterstützen Sie bei allen Schritten – von der ersten Idee bis zur
            Fertigstellung Ihres Hauses. Dabei liegt uns besonders das seniorengerechte und barrierefreie Bauen am
            Herzen, um zentrales und komfortables Wohnen für jede Lebensphase zu ermöglichen.</p>
          <p>Wir verstehen die Herausforderungen im Immobilienbereich und bieten professionelle Problemlösungen. Ob es
            um Baugenehmigungen, Wertermittlungen oder die effektive Immobilienvermarktung geht – Bert Ulrich Weber
            steht Ihnen mit Expertise und Engagement zur Seite.</p>
          <p>Entdecken Sie mit uns die Möglichkeiten, die der Immobilienmarkt in Burscheid bietet. Wir freuen uns
            darauf, Sie auf Ihrem Weg zur Traumimmobilie oder bei der Realisierung Ihres Bauprojekts zu begleiten.
            Lassen Sie uns gemeinsam Ihr Immobilienziel erreichen!</p>
          <p>Ihr Bert Ulrich Weber, Immobilienmakler in Burscheid</p>
        </section>
        <Spacer />
        <section>
          <Heading textAlign="center" tag="h2">Unsere neuesten Angebote</Heading>
          <PropertyGrid limit={2}/>
        </section>
        <section style={{ textAlign: 'center' }}>
          <Heading tag="h2">Sie möchten verkaufen?</Heading>
          <p>Wir beraten Sie gerne unverbindlich!</p>
          <p>Wenn Sie Fragen haben oder eine erste Beratung wünschen, rufen wir Sie gerne zurück.</p>
        </section>
        <Spacer/>
      </Wrapper>
    </>
  )
}

export default IndexPage;

export const query = graphql`
    query {
        headerImages: directusMediaCollection(name: {eq: "index-headers"}) {
            name
            images: media {
                file {
                    localFile {
                        name
                        childImageSharp {
                            fluid(maxWidth: 1980, maxHeight: 700, cropFocus: CENTER) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
        partnerLogos: directusMediaCollection(name: { eq: "partner-logos"}) {
            media {
                file {
                    localFile {
                        name
                        childImageSharp {
                            fixed {
                                ...GatsbyImageSharpFixed
                            }
                        }
                    }
                }
            }
        }
        partners: directusCustomData(title: { eq: "partners"}) {
            content {
                display_name
                content
                values {
                    image
                    link_to
                }
            }
        }
    }
`;